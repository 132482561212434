<template>
  <div id="app">
    <transition name="view" mode="out-in" appear>
      <home v-if="$root.view == 'home'" class="view" />
      <player v-else-if="$root.view == 'player'" class="view" />
    </transition>
  </div>
</template>

<script>
import Home from "./views/Home.vue";
import Player from "./views/Player.vue";

export default {
  name: 'App',
  components: {Home, Player}
}
</script>

<style scoped>
#app {
  position: relative;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
}

.view {
  width: 100%;
  height: 100%;
}

.view-enter-active, .view-leave-active {
  transition: opacity 1s;
}

.view-enter, .view-leave-to {
  opacity: 0;
}
</style>
