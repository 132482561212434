<template>
<transition name="fade" v-on="$listeners">
  <div class="dialog-container" v-if="value">
    <div class="is-overlay background" @click="close" />
    <div class="close">
      <a class="has-text-white" @click="close"><icon name="mdiClose" class="is-medium" /></a>
    </div>
    <div class="is-overlay contents">
      <slot />
    </div>
  </div>
</transition>
</template>

<script>
import Icon from './Icon.vue';

export default {
  name: "OverlayDialog",
  components: {Icon},
  props: {
    value: Boolean,
  },

  methods: {
    close() {
      this.$emit("input", false);
    }
  }
}
</script>

<style scoped>
.dialog-container {
  position: fixed;
  width: 100%;
  height: 100%;
}

.background {
  background-color: rgba(0,0,0,0.4);
  background: radial-gradient(rgba(0,0,0,0.2), rgba(0,0,0,0.6));
  cursor:pointer;
}

.close {
  position: absolute;
  top: 1rem;
  right: 1rem;
}

.contents {
  margin: auto;
  width: 600px;
  max-width: 80%;
  max-height: 60%;
  overflow-y: scroll;
  padding-right: 8px;
}

.contents::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 8px;
}

.contents::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: rgba(255, 255, 255, .5);
}

.fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
}
.fade-enter, .fade-leave-to {
  opacity: 0;
}
</style>