<template>
  <audio
    ref="player"
    @timeupdate="timeUpdate"
    @loadedmetadata="loadedMetadata"
    @loadeddata="loadedData"
    @canplay="canPlay"
    @play="playingUpdate(true)"
    @pause="playingUpdate(false)"
    @waiting="waitingUpdate(true)"
    @playing="waitingUpdate(false)"
    :loop="loop"
    :muted="muted"
  >
    <track
      v-if="chapters"
      ref="chapters"
      kind="chapters"
      :src="chapters"
      @load="loadedChapters"
      @cuechange="chapterCueUpdate"
    />

    <track
      v-for="(value, name) in timedMetadata"
      :key="'metadata-'+name"
      :ref="'metadata-'+name"
      kind="metadata"
      :src="value"
      @load="loadedTimedMetadata(name)"
      @cuechange="timedMetadataCueUpdate(name)"
    />
  </audio>
</template>

<script>
import Hls from "hls.js";

export default {
  name: "AudioPlayer",
  props: {
    source: String,
    chapters: String,
    timedMetadata: Object,
    loop: Boolean,
    muted: Boolean,
    volume: {
      type: Number,
      default: 1
    }
  },

  data() {
    return {
      hls: null,
      fatalError: null,
      seekedTime: 0,
      isLoaded: false
    }
  },

  mounted() {
    let player = this.$refs.player;

    if (Hls.isSupported()) {
      console.debug('Using non-native HLS');

      this.hls = new Hls({
        maxMaxBufferLength: 300
      });
      this.hls.loadSource(this.source);
      this.hls.attachMedia(player);

      this.hls.on(Hls.Events.LEVEL_SWITCHED, (event, data) => {
        let level = this.hls.levels[data.level];
        console.debug(`Using stream: ${data.level} (${level.bitrate / 1000}}kb/s ${level.audioCodec})`);
      });

      this.hls.on(Hls.Events.ERROR, (event, data) => {
        console.error("HLS Error")
        console.error(data);

        if (data.fatal) {
          this.fatalError = data;
          this.$emit('fatalError', data);
        }
      });

    } else if (player.canPlayType('application/vnd.apple.mpegurl')) {
      console.debug('Using native HLS');
      player.src = this.source;
    }

    if (this.chapters) {
      this.$refs.chapters.track.mode = "hidden";
    }

    if (this.timedMetadata) {
      for (let name in this.timedMetadata) {
        let track = this.$refs['metadata-' + name][0].track;
        track.mode = "hidden";
      }
    }

    player.volume = this.volume;
  },

  destroyed() {
    if (this.hls)
      this.hls.destroy();
  },

  methods: {
    loadedMetadata() {
      console.log("Loaded Metadata");
      this.duration = this.$refs.player.duration;
      this.$emit('loadedMetadata', {duration: this.duration});

      // this.$refs.player.currentTime = this.seekedTime;
    },

    loadedData() {
      console.log("Loaded Data");
      this.$refs.player.currentTime = this.seekedTime;
      this.isLoaded = true;
    },

    canPlay() {
      console.log("Can Play");
    },

    loadedChapters() {
      console.log("Loaded Chapters");
      let track = this.$refs.chapters.track;
      track.mode = "hidden";
      this.$emit('loadedChapters', Array.from(track.cues));
    },

    loadedTimedMetadata(name) {
      console.log("Loaded Timed Metadata");
      let track = this.$refs['metadata-' + name][0].track;
      track.mode = "hidden";
      this.$emit('loadedTimedMetadata', name, Array.from(track.cues));
    },

    timeUpdate() {
      this.$emit('currentTime', this.$refs.player.currentTime);
    },

    chapterCueUpdate() {
      let length = this.$refs.chapters.track.activeCues.length;
      let cue = length > 0 ? this.$refs.chapters.track.activeCues[length - 1] : null;
      this.$emit('currentChapter', cue);
    },

    timedMetadataCueUpdate(name) {
      let track = this.$refs['metadata-' + name][0].track;
      this.$emit('currentTimedMetadata', name, Array.from(track.activeCues));
    },

    playingUpdate(playing) {
      console.log("Playing", playing);
      this.$emit('playing', playing);
    },

    waitingUpdate(waiting) {
      console.log("Waiting", waiting);
      this.$emit("waiting", waiting);
    },

    play() {
      this.$refs.player.play();
    },

    pause() {
      this.$refs.player.pause();
    },

    seek(time) {
      console.log("Seek", time);
      this.seekedTime = time;
      if (this.isLoaded) {
        this.$refs.player.currentTime = time;
      }
    },

    recoverFromFatalError() {
      if (this.fatalError.type == Hls.ErrorTypes.NETWORK_ERROR) {
        this.hls.startLoad();
        return true;
      } else if (this.fatalError.type == Hls.ErrorTypes.MEDIA_ERROR) {
        this.hls.recoverMediaError();
        return true;
      }
      return false;
    }

  },

  watch: {
    muted(newVal) {
      this.$refs.player.muted = newVal;
    },

    volume(newVal) {
      this.$refs.player.volume = newVal;
    }
  }
};
</script>
