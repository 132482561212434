<template>
  <div>
    <div class="clock-container">
      <div class="clock-circle" />
      <transition name="hand">
        <div v-if="showHands" class="hand hour" :style="{transform: `rotate(${hourAngle}deg)`, transitionDuration: `${handTransitionDuration}s`}" @transitionend="handTransitionEnded">
          <div :style="{transform: `rotate(${warpHour * 360}deg)`, transitionDuration: `${warpDuration}s`}">
            <div>
              <transition name="hour-hand-img" appear>
                <img src="../assets/hour-hand.png" :style="{transform: `scaleX(${handScale})`, transitionDuration: `${handScaleDuration}s`}" />
              </transition>
            </div>
          </div>
        </div>
      </transition>
      <transition name="hand">
        <div v-if="showHands" class="hand minute" :style="{transform: `rotate(${minuteAngle}deg)`, transitionDuration: `${handTransitionDuration}s`}">
          <div :style="{transform: `rotate(${warpMinute * 360}deg)`, transitionDuration: `${warpDuration}s`}">
            <div>
              <transition name="minute-hand-img" appear>
                <img src="../assets/minute-hand.png" :style="{transform: `scaleX(${handScale})`, transitionDuration: `${handScaleDuration}s`}" />
              </transition>
            </div>
          </div>
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
export default {
  name: "Clock",
  props: {
    time: Number,
    showHands: {
      type: Boolean,
      default: true
    },
    seeking: Boolean,
    handScale: Number,
    handScaleDuration: Number,
    warpHour: Number,
    warpMinute: Number,
    warpDuration: Number
  },

  data() {
    return {
      previousTime: 0,
      timeOffset: 0,
      previousTimeOffset: 0,
    }
  },

  computed: {
    hourAngle() {
      return (this.time + this.timeOffset) / 120;
    },

    minuteAngle() {
      return (this.time + this.timeOffset) / 10;
    },

    handTransitionDuration() {
      if (this.seeking) {
        let delta = Math.abs((this.time + this.timeOffset) - (this.previousTime + this.previousTimeOffset));
        return Math.max(delta / 3600, 2);
      } else {
        return 1;
      }
    }
  },

  methods: {
    handTransitionEnded() {
      if (this.seeking)
        this.$emit('seeked');
    }
  },

  watch: {
    time(newVal, oldVal) {
      this.previousTime = oldVal;
      this.previousTimeOffset = this.timeOffset;
      if (Math.abs(newVal - oldVal) > 43200) {
        if (newVal < oldVal)
          this.timeOffset += 86400;
        else
          this.timeOffset -= 86400; 
      }
    }
  }
}
</script>

<style scoped lang="scss">
@import "../base.scss";

.clock-container {
  position: relative;
  width: 100%;
  padding-top: 100%;
}

.clock-circle {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 50%;
  background-color: $body-background-color;
  box-shadow: 0 30px 35px rgba(0,0,0,0.6);
}

.hand {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  height: 100%;
  transform-origin: top left;
  transition-property: transform;
}

.hand>div {
  width: 100%;
  height: 100%;
  transform-origin: top left;
  transition-property: transform;
}

.hand>div>div {
  display: inline-block;
  margin-top: -50%;
  transform: translate(-50%, -100%) translateY(40px);
}

.hand img {
  transition-property: transform;
}

.hour-hand-img-enter-active, .minute-hand-img-enter-active{
  animation: hand-enter 2s both;
}

.minute-hand-img-enter-active {
  animation-delay: 1.5s;
}

@keyframes hand-enter {
  0% {
    transform: scaleX(4);
    opacity: 0;
  }
  50% {
    transform: scaleX(4);
    opacity: 1;
  }
  100% {
    transform: scaleX(1);
    opacity: 1;
  }
}

.hand-leave-active, .hand-leave-active{
  transition: opacity 1s;
}

.hand-leave-to, .hand-leave-to{
  opacity: 0;
}
</style>