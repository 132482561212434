<template>
<span class="icon" v-on="$listeners">
  <svg viewBox="0 0 24 24">
    <path :d="path" />
  </svg>
</span>
</template>

<script>
// development only
import * as icons from "@mdi/js";

//production
// import {mdiPlay} from "@mdi/js";
// const icons = {mdiPlay};

export default {
  name: "Icon",
  props: {
    name: String
  },
  computed: {
    path() {
      return icons[this.name];
    }
  }
}
</script>

<style scoped>
.icon svg {
  width: 100%;
  height: 100%;
}
</style>