<template>
  <div class="is-relative">
    <transition name="face" appear>
      <img v-show="showFace" src="../assets/face.png" class="is-overlay face" @load="showFace=true" />
    </transition>
    <div class="is-overlay is-flex is-flex-direction-column is-align-items-center is-justify-content-center has-text-centered home" @click="start">
      <h1 class="title is-1 is-size-4-mobile is-spaced">Moments of Grace</h1>
      <h2 class="subtitle is-4 is-size-6-mobile">An oral history of nursing<br>around the clock</h2>
      <button class="button is-outlined is-light is-large is-hidden-mobile mt-4">
        <icon name="mdiPlay" />
        <span>Start</span>
      </button>
      <button class="button is-outlined is-light is-hidden-tablet">
        <icon name="mdiPlay" />
        <span>Start</span>
      </button>
    </div>
  </div>
</template>

<script>
import Icon from '../components/Icon.vue';

export default {
  name: "Home",
  components: {Icon},

  data() {
    return {
      showFace: false
    }
  },

  methods: {
    start() {
      // get user interaction permission for Safari
      let audio = new Audio();
      audio.src = "data:audio/mpeg;base64,SUQzBAAAAAABEVRYWFgAAAAtAAADY29tbWVudABCaWdTb3VuZEJhbmsuY29tIC8gTGFTb25vdGhlcXVlLm9yZwBURU5DAAAAHQAAA1N3aXRjaCBQbHVzIMKpIE5DSCBTb2Z0d2FyZQBUSVQyAAAABgAAAzIyMzUAVFNTRQAAAA8AAANMYXZmNTcuODMuMTAwAAAAAAAAAAAAAAD/80DEAAAAA0gAAAAATEFNRTMuMTAwVVVVVVVVVVVVVUxBTUUzLjEwMFVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVf/zQsRbAAADSAAAAABVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVf/zQMSkAAADSAAAAABVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVV";
      audio.play();
      setTimeout(() => {
        audio.pause();
      }, 1000);

      this.$root.view = 'player';
    }
  }
}
</script>

<style scoped>
.home {
  cursor: pointer;
}

.face {
  width: 80%;
  height: 130%;
  margin: auto;
  margin-top: -10vh;
  object-fit: contain;
}

.face-enter-active {
  transition: opacity 1s;
}
.face-enter {
  opacity: 0;
}
</style>