<template>
  <div>
    <div class="timeline-container">
      <div v-if="chapters" class="chapters">
        <div
          v-for="chapter, index in chapters"
          :key="index"
          class="chapter"
          :style="{width: ((chapter.endTime - chapter.startTime) / duration * 100) + '%'}"
          @click="seekChapter(chapter)"
        >
          <div class="chapter-title is-size-5">{{chapter.data.time}}</div>
        </div>
      </div>
      <div class="playhead" v-if="duration > 0" :style="{transform: 'translateX(' + progress*100 + '%)'}">
        <div />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Timeline",
  props: {
    currentTime: Number,
    duration: Number,
    chapters: Array
  },

  computed: {
    progress() {
      return this.duration > 0 ? this.currentTime / this.duration : 0;
    }
  },

  methods: {
    seekChapter(chapter) {
      this.$emit("seekChapter", chapter);
    }
  }
}
</script>

<style scoped lang="scss">
@import "../base.scss";

.timeline-container {
  height: 2.5rem;
  position: relative;
}

.chapters {
  display: flex;
  width: 100%;
  height: 100%;
}

.chapter {
  position: relative;
  height: 100%;
  border: $primary solid 0;
  border-left-width: 1px;
  background-color: rgba(128, 128, 128, 0.2);
  transition: background-color 0.25s;
  cursor: pointer;
}

.chapter:last-of-type {
  border-right-width: 1px;
}

.chapter .chapter-title {
  position: absolute;
  top:0;
  left: 50%;
  white-space: nowrap;
  transform: translate(-50%, -100%);
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.5s;
}

.chapter:hover {
  background-color: rgba(128, 128, 128, 0.5);
}

.chapter:hover .chapter-title {
  opacity: 1;
}

.playhead {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
}

.playhead > div {
  width: 2px;
  height: 100%;
  background-color: #fff;
  box-shadow: 0 0 16px 4px #fff;
  pointer-events: all;
  cursor: ew-resize;
}

</style>