<template>
  <overlay-dialog :value="value" @input="$emit('input', $event)">
    <div class="content" v-html="content"></div>
  </overlay-dialog>
</template>

<script>
import OverlayDialog from './OverlayDialog.vue';
import axios from 'axios';

export default {
  name: "AboutDialog",
  components: {OverlayDialog},
  props: {
    value: Boolean,
  },

  data() {
    return {
      content: null
    }
  },

  created() {
    axios.get('/about.html').then((response) => {
      this.content = response.data;
    });
  }
}
</script>

<style scoped>
.content >>> .pre-wrap {
  white-space: pre-wrap;
}
</style>