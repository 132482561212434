<template>
  <overlay-dialog :value="value" @input="$emit('input', $event)" @enter="scrollToCurrentChapter">
    <div class="contents" ref="contents">
      <ul>
        <li v-for="chapter, index in chapters" :key="index" ref="chapter" class="is-size-5 has-text-centered">
          <div
            class="chapter p-4"
            :class="chapter === currentChapter ? 'has-text-primary active' : 'has-text-white'"
            @click="seekChapter(chapter)"
          >
            <p class="title is-5">{{chapter.data.time}}</p>
            <p class="subtitle is-6">{{chapter.data.description}}</p>
          </div>
        </li>
      </ul>
    </div>
  </overlay-dialog>
</template>

<script>
import OverlayDialog from './OverlayDialog.vue';

export default {
  name: "ChapterDialog",
  components: {OverlayDialog},
  props: {
    value: Boolean,
    chapters: Array,
    currentChapter: VTTCue
  },

  methods: {
    scrollToCurrentChapter() {
      let index = this.chapters.indexOf(this.currentChapter);
      if (index >= 0) {
        let container = this.$refs.contents.parentElement;
        let el = this.$refs.chapter[index];
        container.scrollTop = el.offsetTop - container.clientHeight/2 + el.clientHeight / 2;
      }
    },

    seekChapter(chapter) {
      this.$emit("seekChapter", chapter);
      this.$emit("input", false);
    },
  }
}
</script>

<style scoped>
li {
  position: relative;
}

li:not(:last-of-type):after {
  content: "";
  display: block;
  width: 10rem;
  margin: auto;
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
}

.chapter {
  cursor: pointer;
  opacity: 0.5;
  transition: opacity 0.25s;
}

.chapter:hover, .chapter.active {
  opacity: 1;
}

.title, .subtitle {
  color: inherit;
}
</style>