import Vue from 'vue'
import Buefy from 'buefy'
import App from './App.vue'
import './base.scss'

Vue.use(Buefy);

Vue.config.productionTip = false

new Vue({
  render: h => h(App),
  data: {
    view: 'home'
  }
}).$mount('#app')
