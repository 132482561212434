<template>
  <div>
    <div class="background" :style="{backgroundColor, transitionDuration: `${backgroundDuration}s`}">

      <transition name="radial" appear v-for="effect in radialEffects" :key="effect.id">
        <div class="radial" :style="{
          width: ((effect.size + effect.gradientSize) * 100) + 'vw',
          height: ((effect.size + effect.gradientSize) * 100) + 'vw',
          background: `radial-gradient(circle closest-side at center, ${effect.color} ${effect.size * 50}vw, transparent 100%)`,
          transition: `transform ${effect.inDuration}s, opacity ${effect.outDuration}s`
        }"></div>
      </transition>

      <div class="is-overlay is-flex is-justify-content-center is-align-items-center">
        <div class="clock-container">
          <clock
            :time="clockTime"
            :show-hands="showHands"
            :seeking="isSeeking"
            @seeked="clockSeeked"
            :hand-scale="handScale"
            :hand-scale-duration="handScaleDuration"
            :warpHour="warpHour"
            :warpMinute="warpMinute"
            :warpDuration="warpDuration"
          />
          <transition name="chapter-info">
            <div class="is-overlay chapter-info" v-if="chapterInfo">
              <p class="title is-1 is-size-4-mobile is-spaced">{{chapterInfo.data.time}}</p>
              <p class="subtitle is-size-7-mobile">{{chapterInfo.data.description}}</p>
            </div>
          </transition>
          <template v-if="!isHidingText">
            <transition v-for="quote in quotes" :key="quote.id" name="quote-out">
              <div class="is-overlay is-flex is-justify-content-center is-align-items-center quote" :style="{transitionDuration: `${quote.outDuration}s`}">
                <transition name="quote-in" appear>
                  <div :style="{color: quote.color, transitionDuration: `${quote.inDuration}s`}">
                    <p class="is-size-5 is-size-7-mobile mb-3">{{quote.text}}</p>
                    <p class="is-size-6 is-size-7-mobile has-text-right">{{quote.author}}</p>
                  </div>
                </transition>
              </div>
            </transition>
          </template>
        </div>
      </div>

      <transition name="controls">
        <div v-if="showControls" class="is-overlay main">
          <div>
            <div class="is-flex is-justify-content-space-between">
              <div class="is-flex-shrink-1">
                <h1 class="subtitle is-uppercase">Moments of Grace</h1>
              </div>
              <transition name="controls">
                <div v-if="!showingDialog" class="is-flex-shrink-0 has-text-right is-uppercase">
                  <div class="mb-2">
                    <a class="has-text-white" @click="showingAbout = true">About</a>
                  </div>
                  <div class="mb-2">
                    Sound
                    <a class="has-text-white" :class="{'has-text-primary': !isMuted}" @click="isMuted=false">On</a>
                    /
                    <a class="has-text-white" :class="{'has-text-primary': isMuted}" @click="isMuted=true">Off</a>
                  </div>
                  <div>
                    Text
                    <a class="has-text-white" :class="{'has-text-primary': !isHidingText}" @click="isHidingText=false">On</a>
                    /
                    <a class="has-text-white" :class="{'has-text-primary': isHidingText}" @click="isHidingText=true">Off</a>
                  </div>
                </div>
              </transition>
            </div>
          </div>
          <div>
            <div class="is-flex is-align-items-center is-justify-content-space-between">
              <div class="is-inline-flex mr-4 has-text-primary play-pause">
                <div v-if="isSeeking || (isWaiting && !fatalError)" class="loading" />
                <icon v-else-if="fatalError" name="mdiReloadAlert" @click="recoverFromFatalError" />
                <icon v-else-if="isPlaying && !isFadingOut" name="mdiPause" @click="pause" />
                <icon v-else name="mdiPlay" @click="play" />
              </div>
              
              <timeline
                :currentTime="currentTime"
                :duration="duration"
                :chapters="chapters"
                @seekChapter="seekChapter"
                class="is-flex-grow-1 is-hidden-mobile" />

              <div class="is-inline-flex ml-4 has-text-primary play-pause">
                <icon name="mdiMenuSwap" @click="showingChapters = !showingChapters" />
              </div>
            </div>
          </div>
        </div>
      </transition>

      <audio-player
        ref="player"
        source="audio/moments-of-grace/hls/master.m3u8"
        chapters="audio/moments-of-grace/chapters.webvtt"
        :timedMetadata="{quotes: 'audio/moments-of-grace/quotes.webvtt', effects: 'audio/moments-of-grace/effects.webvtt'}"
        @loadedMetadata="duration = $event.duration"
        @loadedChapters="loadedChapters"
        @playing="isPlaying = $event"
        @waiting="isWaiting = $event"
        @currentTime="currentTimeChanged"
        @currentChapter="currentChapterChanged"
        @currentTimedMetadata="currentTimedMetadataChanged"
        @fatalError="fatalError = $event"
        loop
        :muted="isMuted"
        :volume="volume"
      />

      <chapter-dialog v-model="showingChapters" :chapters="chapters" :currentChapter="currentChapter" @seekChapter="seekChapter" />
      <about-dialog v-model="showingAbout" />
    </div>
  </div>
</template>

<script>
import AudioPlayer from '../components/AudioPlayer.vue';
import Clock from '../components/Clock.vue';
import Timeline from '../components/Timeline.vue';
import AboutDialog from '../components/AboutDialog.vue';
import ChapterDialog from '../components/ChapterDialog.vue';
import Icon from '../components/Icon.vue';

const parseObject_re = /^\s*(\w+)\s*:\s*(.+?)\s*$/mg;
function parseObject(text) {
  let obj = {};
  for (let match of text.matchAll(parseObject_re)) {
    obj[match[1]] = match[2];
  }
  return obj;
}

function parseClockTime(text) {
  let components = text.split(':');
  let hours = parseInt(components[0]);
  let minutes = parseInt(components[1]);
  return hours * 3600 + minutes * 60;
}

function makeEffectObject(effect, id, duration) {
  if (effect.effect == 'radial') {
    effect = Object.assign({
      color: 'black',
      size: 1,
      gradientSize: 0.5,
      inDuration: 1,
      outDuration: 1,
    }, effect);
  } else if (effect.effect == 'background') {
    effect = Object.assign({
      color: 'black',
      inDuration: 1,
      outDuration: 1,
    }, effect);
  } else if (effect.effect == 'handScale') {
    effect = Object.assign({
      scale: 2,
      inDuration: 1,
      outDuration: 1,
    }, effect);
  } else if (effect.effect == 'timeWarp') {
    effect = Object.assign({
      minute: 0,
      hour: 0
    }, effect);
  }

  for (let key in effect) {
    let number = parseFloat(effect[key]);
    if (!isNaN(number))
      effect[key] = number;
  }

  effect.id = id;
  effect.duration = duration;
  return effect;
}

export default {
  name: 'Player',
  components: {AudioPlayer, Clock, Timeline, AboutDialog, ChapterDialog, Icon},
  data() {
    return {
      fatalError: null,
      
      currentTime: 0,
      duration: 0,
      chapters: null,
      currentChapter: null,

      isPlaying: false,
      isWaiting: false,
      isSeeking: false,
      
      fadeTimer: null,
      volume: 0,
      isFadingIn: false,
      isFadingOut: false,

      showHands: false,
      showControls: false,
      
      chapterInfo: null,
      chapterInfoTimer: null,

      isMuted: false,
      isHidingText: false,
      
      showingAbout: false,
      showingChapters: false,
      resumePlayback: false,

      effects: [],
      quotes: [],

      backgroundColor: null,
      backgroundDuration: 0,

      handScale: 1,
      handScaleDuration: 1,

      warpHour: 0,
      warpMinute: 0,
      warpDuration: 0
    }
  },

  computed: {
    clockTime() {
      let chapter = this.currentChapter;
      if (!chapter)
        return 0;

      let progress = (this.currentTime - chapter.startTime) / (chapter.endTime - chapter.startTime);
      return progress * (chapter.clockEndTime - chapter.clockStartTime) + chapter.clockStartTime;
    },

    showingDialog() {
      return this.showingAbout || this.showingChapters;
    },

    radialEffects() {
      return this.effects.filter((effect) => effect.effect === 'radial');
    },

    backgroundEffect() {
      let background = null;
      for (let effect of this.effects) {
        if (effect.effect === 'background')
          background = effect;
      }
      return background;
    },

    handScaleEffect() {
      let handScale = null;
      for (let effect of this.effects) {
        if (effect.effect === 'handScale')
          handScale = effect;
      }
      return handScale;
    },

    timeWarpEffect() {
      let timeWarp = null;
      for (let effect of this.effects) {
        if (effect.effect === 'timeWarp')
          timeWarp = effect;
      }
      return timeWarp;
    }
  },

  created() {
    window.effect = (effect, duration) => {
      effect = makeEffectObject(effect, Date.now(), duration);
      this.effects.push(effect);
      setTimeout(() => {
        this.effects.splice(this.effects.indexOf(effect), 1);
      }, duration * 1000);
    }

    window.quote = (effect, duration) => {
      let defaultEffect = {
        id: Date.now(),
        color: 'white',
        inDuration: 1,
        outDuration: 1,
      }
      effect = Object.assign(defaultEffect, effect);
      this.quotes.push(effect);
      setTimeout(() => {
        this.quotes.splice(this.quotes.indexOf(effect), 1);
      }, duration * 1000);
    }
  },

  methods: {
    loadedChapters(chapters) {
      this.chapters = chapters;
      for (let chapter of this.chapters) {
        chapter.data = parseObject(chapter.text);
        chapter.clockStartTime = parseClockTime(chapter.data.time);
      }

      for (let i=0; i<this.chapters.length; i++) {
        if (i + 1 < this.chapters.length)
          this.chapters[i].clockEndTime = this.chapters[i + 1].clockStartTime;
        else
          this.chapters[i].clockEndTime = this.chapters[0].clockStartTime + 86400;
      }

      let now = new Date();
      let nowClockTime = now.getSeconds() + now.getMinutes() * 60 + now.getHours() * 3600;
      for (let chapter of this.chapters) {
        if (nowClockTime >= chapter.clockStartTime && nowClockTime < chapter.clockEndTime) {
          this.currentTime = chapter.startTime;
          this.currentChapter = chapter;
          this.$refs.player.seek(chapter.startTime);
          break;
        }
      }
      
      setTimeout(() => {
        this.showHands = true;
        setTimeout(() => {
          this.showControls = true;
          this.showChapterInfo();
          this.play();
        }, 4000);
      }, 1000);
    },

    currentTimeChanged(time) {
      if (!this.isSeeking)
        this.currentTime = time;
    },

    currentChapterChanged(chapter) {
      if (this.isSeeking)
        return;

      if (chapter !== this.currentChapter) { // seems like a browser bug, but this does sometimes happen
        this.currentChapter = chapter;
        if (!this.isSeeking && this.showControls && !this.showingDialog) {
          this.showChapterInfo();
        }
      }
    },

    currentTimedMetadataChanged(name, cues) {
      if (!this.isPlaying || this.isFadingOut)
        return;

      if (name === 'quotes') {
        this.quotes = cues.map((cue) => {
          let defaultEffect = {
            id: cue.id,
            color: 'white',
            inDuration: 1,
            outDuration: 1,
          }
          return Object.assign(defaultEffect, parseObject(cue.text));
        })

      } else if (name === 'effects') {
        this.effects = cues.map((cue) => {
          let effect = parseObject(cue.text);
          return makeEffectObject(effect, cue.id, cue.endTime - cue.startTime);
        });
      }
    },

    showChapterInfo() {
      this.chapterInfo = this.currentChapter;
      clearTimeout(this.chapterInfoTimer);
      this.chapterInfoTimer = setTimeout(() => {
        this.chapterInfo = null;
      }, 7.5*1000);
    },

    hideChapterInfo() {
      this.chapterInfo = null;
      clearTimeout(this.chapterInfoTimer);
    },

    seekChapter(chapter) {
      this.isSeeking = true;
      this.pause();
      this.hideChapterInfo();
      this.currentTime = chapter.startTime;
      this.currentChapter = chapter;
    },

    clockSeeked() {
      this.$refs.player.seek(this.currentTime);
      this.isSeeking = false;
      if (!this.showingDialog) {
        this.showChapterInfo();
        this.play();
      }
    },

    play() {
      this.isFadingIn = true;
      this.isFadingOut = false;
      this.$refs.player.play();
      clearInterval(this.fadeTimer);
      this.fadeTimer = setInterval(() => {
        this.volume = Math.min(this.volume + 0.1, 1);
        if (this.volume === 1) {
          clearInterval(this.fadeTimer);
          this.isFadingIn = false;
        }
      }, 50);
    },

    pause() {
      this.isFadingOut = true;
      this.isFadingIn = false;

      this.quotes = [];
      this.effects = [];

      clearInterval(this.fadeTimer);
      this.fadeTimer = setInterval(() => {
        this.volume = Math.max(this.volume - 0.2, 0);
        if (this.volume === 0) {
          this.$refs.player.pause();
          clearInterval(this.fadeTimer);
          this.isFadingOut = false;
          
          if (this.isSeeking)
            this.$refs.player.seek(this.currentTime); // preload
        }
      }, 50);
    },

    recoverFromFatalError() {
      if (this.$refs.player.recoverFromFatalError(this.fatalError))
        this.fatalError = null;
    }
  },

  watch: {
    isPlaying(newVal) {
      if (this.isSeeking) {
        if (newVal)
          this.$refs.player.pause();
      }
      else if (newVal && !this.isFadingIn)
        this.play();

      else if (!newVal && !this.isFadingOut) {
        this.pause();
      }
    },

    showingDialog(newVal) {
      if (newVal) {
        this.resumePlayback = this.isPlaying || this.isSeeking;
        this.pause();
        this.hideChapterInfo();
      } else if (this.resumePlayback && !this.isSeeking && !this.fatalError) {
        this.play();
      }
    },

    backgroundEffect(newVal, oldVal) {
      if (newVal) {
        this.backgroundColor = newVal.color;
        this.backgroundDuration = newVal.inDuration;
      } else if (oldVal) {
        this.backgroundColor = null;
        this.backgroundDuration = oldVal.outDuration
      }
    },

    handScaleEffect(newVal, oldVal) {
      if (newVal) {
        this.handScale = newVal.scale;
        this.handScaleDuration = newVal.inDuration;
      } else if (oldVal) {
        this.handScale = 1;
        this.handScaleDuration = oldVal.outDuration;
      }
    },

    timeWarpEffect(newVal, oldVal) {
      if (newVal && (!oldVal || newVal.id !== oldVal.id)) {
        this.warpHour += Math.floor(newVal.hour);
        this.warpMinute += Math.floor(newVal.minute);
        this.warpDuration = newVal.duration;
      }
    }
  }
}
</script>

<style scoped>
.clock-container {
  width: 400px;
  max-width: 55%;
  position: relative;
}

.chapter-info {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 10%;
  text-align: center;
  transition: opacity 1s;
}

.chapter-info-enter, .chapter-info-leave-to {
  opacity: 0;
}

.main {
  padding: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.controls-enter-active, .controls-leave-active {
  transition: opacity 1s;
}

.controls-enter, .controls-leave-to {
  opacity: 0;
}

.play-pause .icon {
  width: 2rem;
  height: 2rem;
  opacity: 0.5;
  transition: opacity 0.25s;
  cursor: pointer;
}

.play-pause .icon:hover {
  opacity: 1;
}

.play-pause .loading {
  border-radius: 50%;
  width: 1.5rem;
  height: 1.5rem;
  margin: 0.25rem;
  border: 2px solid;
  opacity: 0.5;
  border-bottom-color: transparent;
  border-right-color: transparent;
  border-left-color: transparent;
  animation: spin 1s infinite linear;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.background {
  transition-property: background-color;
  width: 100%;
  height: 100%;
}

.radial {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) scale(1);
  opacity: 1;
}

.radial-enter {
  transform: translate(-50%, -50%) scale(0);
}

.radial-leave-to {
  opacity: 0;
}

.quote {
  padding: 15%;
}

.quote-in-enter-active, .quote-out-leave-active {
  transition-property: opacity;
}

.quote-in-enter, .quote-out-leave-to {
  opacity: 0;
}
</style>
